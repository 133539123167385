/* Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  color: black;
}

.logo img {
  height: 40px;
}

.search-field {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 8px 30px 10px 40px;
  /* background-image: url("../../assets/Header/search.svg"); */
  /* background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px; */
  border: 1px solid #ccc;
  border-radius: 5px;
  /* width: 100%; */
  /* padding-left: 40px !important; */
  margin: auto;
  width: 600px;
  height: 55px;
  border-radius: 14.46px;
  box-shadow: -2px -2px 4px 0px #00000021 inset;
}

.add-button {
  margin-right: 25px;
  background-color: rgba(136, 61, 207, 1);
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.back-arrow {
  width: 30px !important;
  height: 30px !important;
}

.back-arrow:hover {
  cursor: pointer;
}

.mobile_nav_bar {
  display: none !important;
}



@media (min-width:320px) and (max-width:1024px) {
  .mobile-view-header-title {
    margin-left: 15px !important;
  }

  .mobile_nav_bar {
    display: block !important;
  }
}