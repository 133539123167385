.bg-img {
    background-image: url('../../assets/login/auth-bg-big-main.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.card {
    padding: 2rem;
    background: transparent !important;
}

.form-group {
    margin-bottom: 1rem;
}

.header-lable {
    color: white;
}

.input-field {
    width: 60%;
    height: 55px;
    border-radius: 16px;
    border: none;
    background-color: white;
    box-shadow: none;
}

.field-icon {
    width: 7%;
    margin: auto 15px;
}

.form {
    text-align: -webkit-center;
}

.email_icon {
    width: 42px;
    height: 32px;
    padding: 5px;
    margin: auto;
}

.pass_icon {
    width: 36px;
    height: 36px;
    padding: 5px;
    margin: auto;
}

.input-field input {
    width: 60%;
    height: 50px;
    border: none;
    outline: none;
    margin-top: 2px;
    box-shadow: none;
}

label {
    display: block;
    margin-bottom: 0.5rem;
}

input[type='text'],
input[type='password'] {
    padding: 0.5rem;
    font-size: 1rem;
}

button[type='submit'] {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    cursor: pointer;
    border: 1.07px solid #FFFFFF;
    background: none;
    border-radius: 16px;
    height: 42px;
    width: 213px;
}

.eye-off {
    width: 30px;
    height: 29px;
    margin: auto 10px auto auto;
}

.eye-on {
    width: 24px;
    height: 18px;
    margin: auto 11px auto auto;
}

.forgot-password {
    width: 50%;
}

.forgot-password:hover {
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .email_icon {
        width: 38px !important;
    }

    .input-field {
        width: 100% !important;
    }

    .submit-btn {
        width: 150px !important;
    }
}