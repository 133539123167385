.forgot-bg-img {
    background-image: url('../../assets/forgotpassoword/forgotpassword_background.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.forgot_password_image {
    background-image: url('../../assets/forgotpassoword/forgot_password_image.jpg');
    background-size: cover;
    background-position: center;
    height: 90vh;
}

.forgot-pass .card {
    padding: 2rem;
    background: transparent !important;
    box-shadow: none !important;
    border: none !important;
}

.forgot-form-container {
    padding-top: 9rem !important;
}

.forgot-form .form-group {
    margin-bottom: 1rem;
}

.forgot-form .header-lable {
    color: white;
}

.forgot-form .input-field {
    width: 60%;
    height: 55px;
    border-radius: 16px;
    border: none;
    background-color: white;
    box-shadow: none;
}

.forgot-form field-icon {
    width: 7%;
    margin: auto 15px;
}

.forgot-form {
    text-align: -webkit-center;
}

.forgot-form .email_icon {
    width: 42px;
    height: 32px;
    padding: 5px;
    margin: auto;
}

.forgot-form .pass_icon {
    width: 36px;
    height: 36px;
    padding: 5px;
    margin: auto;
}

.forgot-form .input-field input {
    width: 60%;
    height: 50px;
    border: none;
    outline: none;
    margin-top: 2px;
    box-shadow: none;
}

label {
    display: block;
    margin-bottom: 0.5rem;
}

input[type='text'],
input[type='password'] {
    padding: 0.5rem;
    font-size: 1rem;
}

button[type='submit'] {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    cursor: pointer;
    border: 1.07px solid #FFFFFF;
    background: none;
    border-radius: 16px;
    height: 42px;
    width: 213px;
}

.loginbtn {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    cursor: pointer;
    border: 1.07px solid #FFFFFF;
    background: none;
    border-radius: 16px;
    height: 42px;
    width: 213px;
}

.eye-off {
    width: 30px;
    height: 29px;
    margin: auto 10px auto auto;
}

.eye-on {
    width: 24px;
    height: 18px;
    margin: auto 11px auto auto;
}

.back-to-text{
    width: 40%;
}

.back-to-text:hover {
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .email_icon {
        width: 38px !important;
    }

    .input-field {
        width: 100% !important;
    }

    .submit-btn {
        width: 150px !important;
    }

    .forgot_password_image {
        display: none !important;
    }

    .forgot-form-container {
        padding-top: 4rem !important;
    }
}