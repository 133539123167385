/* Sidebar.css */
.sidebar {
  width: 250px;
  min-width: 50px;
  height: 100vh;
  color: white;
  transition: width 0.3s ease;
  overflow-x: unset;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background: #0F090C;
  border: 0.5px solid #B86E9FCC;
}

.sidebar.open {
  width: 250px;
}

.sidebar.closed {
  width: 90px;
}

.sidebar-toggle {
  position: absolute;
  top: 50px;
  right: -22px;
  display: flex;
  align-items: center;
  padding: 10px;
  z-index: 1000;
}

.sidebar-toggle img,
.sidebar-toggle svg {
  width: 24px;
  height: 24px;
  background: black;
  cursor: pointer;
  color: #fff;
  border: 0.5px solid #B86E9FCC;
  border-radius: 50%;
}

.sidebar-menu {
  padding-bottom: 40px;
  position: relative;
  list-style-type: none;
}

.sidebar-menu li span {
  font-family: 'circular';
}

.sidebar-title {
  margin: 15px 29px 0px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4000000059604645px;
  text-align: center;
  color: #FFFFFF;
}

.menu-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  border-radius: 7px;
  padding-left: 4px;
  text-decoration: none;
}

.menu-button img,
.menu-button svg {
  margin-left: 5px;
}

.arrow-icon {
  width: 20px;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.header-content {
  display: flex;
  align-items: center;
}

.sidebar-heading {
  display: grid;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 12px;
}

.right-arrow {
  position: absolute;
  right: 25px;
  height: 30px;
  width: 30px;
}


.streetlights {
  width: 50px;
  height: 12px;
  margin: auto 12px;
}

.profile-logo {
  width: 48px;
  height: 48px;
  margin: 25px 13px;
}

.profile-name {
  margin-top: 40px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-transform: capitalize;
}

.horizontal-line {
  margin: 2% 17% 5%;
  color: #A33B3B;
  opacity: 30%;
}

.menu-icon-dash {
  width: 24px;
  height: 24px;
  margin-left: 15px !important;
}

.menu-icon-file {
  width: 28px;
  height: 28px;
  margin-left: 14px !important;
}

.menu-icon-logout-active {
  width: 28px;
  height: 28px;
  margin-left: 14px !important;
}

.menu-icon-file-active {
  width: 23px;
  height: 23px;
  margin-left: 14px !important;
}



.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  z-index: 1000;
  display: contents !important;
}

.dropdown-menu li {
  list-style: none;
  color: #fff;
  margin-left: 40px;
}

.dropdown-menu li a {
  display: block;
  padding: 8px 16px;
  color: #fff;
  text-decoration: none;
}

.report-menu-open {
  display: inline-block;
  position: absolute;
  left: 50px;
  top: 200px;
  text-align: left;
  background: #0F090C;
  border: 0.5px solid #B86E9FCC;
  width: 190%;
}

.report-menu-open li {
  list-style: none;
  color: #fff;
  margin-left: 0px !important;
}

.report-menu-open li a {
  display: block;
  padding: 8px 16px;
  color: #fff;
  text-decoration: none;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}