.heading {
    font-family: circular;
    font-size: 26px;
    font-weight: 600;
    line-height: 32.84px;
    text-align: left;
}

.add-user-btn {
    height: 45px;
    border-radius: 12px;
    background: linear-gradient(0deg, #000000, #000000), radial-gradient(63.11% 63.11% at 31.97% 19.67%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
    color: white;
    justify-content: center;
    place-items: center;
    padding: 3%;
}

.add-user {
    margin: auto;
    display: flex;
    justify-content: end;
}

.user-form {
    padding: 0% 5% 5% 5%;

    .user-input-field {
        width: 100%;
        border-radius: 14px;
        border: none;
        padding-left: 20px;
        justify-content: space-between;
        height: 50px;
        box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.23) inset;
        outline: none;

        input {
            border: none !important;
            outline: none !important;
            height: 45px !important;
            box-shadow: none !important;
            padding-left: 0px !important;
        }
    }
}

.user-form input {
    width: 100%;
    border-radius: 14px;
    border: none;
    padding-left: 20px;
    height: 50px;
    box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.23) inset;
    outline: none;
}

.user-form label {
    margin: 2% 0% 1% 1%;
    font-family: circular;
    font-size: 16px;
    font-weight: 600;
}

.modal-body {
    max-height: 500px !important;
    overflow-y: scroll !important;
}

.action-btn {
    gap: 10px;
    justify-content: center;
}

.action-btn button {
    border-radius: 5px;
}

.action-btn button:hover {
    transition: background-color 0.3s ease;
}

.eye-offadd {
    height: 25px;
    width: 25px;
    align-items: center;
    justify-content: center;
    margin: 14px;
}

.eye-onadd {
    height: 16px;
    width: 22px;
    align-items: center;
    justify-content: center;
    margin: 16px;
}

.select2-container--open .select2-dropdown--below {
    z-index: 99999 !important;
}

.select2-container--open .select2-dropdown--above {
    z-index: 99999 !important;
}

@media (min-width:320px) and (max-width:768px) {
    .container {
        margin-bottom: 20px !important;
    }

    .user-mobile-view {
        overflow-x: scroll !important;

        table {
            width: 200% !important;
        }
    }

    .pagination-and-info {
        width: 200% !important;
    }

    .add-user-btn {
        margin-top: 5% !important;
    }

    .add-user {
        margin: auto;
        display: flex;
        justify-content: start;
    }
}