.card {
    border-radius: 16px;
    background: white;
    box-shadow: 0px 0px 30px 2px #0C0A0B21;
}

.pl-3 {
    padding-left: 3% !important;

    .card {
        border-radius: 16px;
        background: white;
        box-shadow: 0px 0px 30px 2px #0C0A0B21;
    }

    .pl-3 {
        padding-left: 3% !important;
    }

    .pr-3 {
        padding-right: 3% !important;
    }

    .branch-name {
        font-family: circular;
        font-size: 19.37px;
        font-weight: 600;
        line-height: 27.11px;
        letter-spacing: 0.14524905383586884px;
        text-align: left;
        padding: 0px;
        margin: auto;
    }

    .filtered-date {
        font-family: circular;
        font-size: 13.56px;
        font-weight: 400;
        line-height: 19.37px;
        letter-spacing: 0.005em;
        text-align: left;
        margin: auto;
        padding: 0px;
        color: rgba(0, 0, 0, 1);
    }

    .download-btn {
        width: 116.91px;
        height: 35.97px;
        padding: 7.19px 7.14px 7.19px 3.6px;
        border-radius: 7px;
        border: 0.9px solid rgba(0, 0, 0, 1);
        background: white;
        float: right !important;
        transition: transform 250ms;
    }

    .download-btn:hover {
        transform: translateY(-10px);
    }

    .download-image {
        width: 21.58px;
        height: 21.58px;
        padding: 1.81px 1.82px 1.8px 1.79px;
    }

    .table-main-header {
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: rgba(0, 0, 0, 1);
        padding-bottom: 2%;
    }

    table {
        width: 100%;
    }

    .head-row {
        height: 66px;
        text-align: center;
    }

    .head-row td {
        font-family: circular;
        font-size: 20px;
        font-weight: 500;
        line-height: 25.18px;
        letter-spacing: 0.01em;
        text-align: center;
    }

    .report-table .head-row td {
        font-family: circular;
        font-size: 20px;
        font-weight: 500;
        line-height: 25.18px;
        letter-spacing: 0.01em;
        text-align: center;
    }

    .body-row {
        height: 66px;
        text-align: center;
    }

    .body-row td {
        font-family: circular;
        font-size: 16px;
        font-weight: 400;
        line-height: 27.64px;
        letter-spacing: 0.005em;
        text-align: center;
    }

    .body-row:nth-child(odd) {
        background: rgba(182, 182, 182, 0.1);
    }

    .body-row-eod {
        height: 66px;
        text-align: center;
    }
    
    .body-row-eod td {
        font-family: circular;
        font-size: 16px;
        font-weight: 400;
        line-height: 27.64px;
        letter-spacing: 0.005em;
        text-align: center;
    }
    
    /* .body-row-eod:nth-child(odd) {
        background: rgba(182, 182, 182, 0.1);
    } */

    .orange-row {
        background: #efb853b0;
    }

    .red-row {
        background: #f78989ba;
    }

    .green-row {
        background: #7df77d96;
    }

    .app-no {
        display: grid;
        place-content: center;
    }

    .app-no p {
        /* width: 77px;
        height: 33px; */
        padding: 2.76px 16.56px 2.76px 16.56px;
        border: 1.38px solid rgba(0, 0, 0, 1);
        border-radius: 11.04px;
        display: flex;
    }

    /* Pagination styles */
    .pagination {
        display: flex;
        justify-content: end;
        margin-top: 40px;
    }

    .pagination li {
        display: inline-block;
        margin: 0 5px;
        padding: 5px;
        cursor: pointer;
        /* height: 30px; */
        /* width: 30px; */
        text-align: center;
        background-color: white;
        color: black;
        /* border-radius: 7px; */

        width: 38px;
        height: 38px;
        border: 1.19px solid rgba(0, 0, 0, 1);
        border-radius: 8px;
    }

    .pagination li.active {
        background: black;
        color: white;
    }

    .pagination-and-info {
        display: flex;
        justify-content: space-between;
    }

    .items-info {
        margin-top: 35px;
        font-size: 14px;
        color: #666;
    }
}

.pr-3 {
    padding-right: 3% !important;
}

.branch-name {
    font-family: circular;
    font-size: 19.37px;
    font-weight: 600;
    line-height: 27.11px;
    letter-spacing: 0.14524905383586884px;
    text-align: left;
    padding: 0px;
    margin: auto;
}

.filtered-date {
    font-family: circular;
    font-size: 13.56px;
    font-weight: 400;
    line-height: 19.37px;
    letter-spacing: 0.005em;
    text-align: left;
    margin: auto;
    padding: 0px;
    color: rgba(0, 0, 0, 1);
}

.download-btn {
    width: 116.91px;
    height: 35.97px;
    padding: 7.19px 7.14px 7.19px 3.6px;
    border-radius: 7px;
    border: 0.9px solid rgba(0, 0, 0, 1);
    background: white;
    float: right !important;
    transition: transform 250ms;
}

.download-btn:hover {
    transform: translateY(-10px);
}

.download-image {
    width: 21.58px;
    height: 21.58px;
    padding: 1.81px 1.82px 1.8px 1.79px;
}

.table-main-header {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 1);
    padding-bottom: 2%;
}

table {
    width: 100%;
}

.head-row {
    height: 66px;
    text-align: center;
}

.head-row td {
    font-family: circular;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.18px;
    letter-spacing: 0.01em;
    text-align: center;
}

.report-table .head-row td {
    font-family: circular;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.18px;
    letter-spacing: 0.01em;
    text-align: center;
}

.app-no {
    display: grid;
    place-content: center;
}

.app-no-style p {
    /* width: 77px;
    height: 33px; */
    padding: 2.76px 16.56px 2.76px 16.56px;
    border: 1.38px solid rgba(0, 0, 0, 1);
    border-radius: 11.04px;
    display: flex;
}

/* Pagination styles */
.pagination {
    display: flex;
    justify-content: end;
    margin-top: 20px;
}

.pagination li {
    display: inline-block;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    /* height: 30px; */
    /* width: 30px; */
    text-align: center;
    background-color: white;
    color: black;
    /* border-radius: 7px; */

    width: 38px;
    height: 38px;
    border: 1.19px solid rgba(0, 0, 0, 1);
    border-radius: 8px;
}

.pagination li.active {
    background: black;
    color: white;
}

.pagination-and-info {
    display: flex;
    justify-content: space-between;
}

.items-info {
    margin-top: 35px;
    font-size: 14px;
    color: #666;
}