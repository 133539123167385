@import url("https://pro.fontawesome.com/releases/v6.0.0-beta1/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@500;600;900&display=swap");

.file-status-container {
  margin-left: -4pc;
  padding: 10px;
}
.table-space {
  padding: 20.5px 17.57px 20.5px 17.57px;
}

.file-status-queue {
  margin-inline: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  justify-content: center;
  /* gap: 1rem; */
  list-style: none;
}

.file-status-queue li {
  /* max-width: 12rem; */
  justify-self: center;
  display: grid;
  grid-template:
    "icon"
    "line"
    "dot"
    "title"
    "descr" 1fr;
  justify-items: center;
  align-items: flex-start;
  text-align: center;
}

.file-status-queue li .icon {
  grid-area: icon;
  width: 3rem;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  color: white;
  font-size: 1.4rem;
  border: 0.4rem solid var(--bgColor);
  border-radius: 50%;
  /* box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.45),
        inset -0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.45); */
  /* background: linear-gradient(0deg, #00861D, #00861D),
        radial-gradient(63.11% 63.11% at 31.97% 19.67%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%); */
}

/* .file-status-queue li .icon1 {
    grid-area: icon;
    width: 3rem;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    color: white;
    font-size: 1.4rem;
    border: 0.4rem solid var(--bgColor);
    border-radius: 50%;
    box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.45),
        inset -0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.45);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), radial-gradient(63.11% 63.11% at 31.97% 19.67%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
} */

/* .file-status-queue li::before {
    content: "";
    grid-area: line;
    height: 2rem;
    border-right: 2px dotted currentColor;
} */

.file-status-queue li::after {
  content: "";
  grid-area: dot;
  width: 1rem;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--accent-color);
  justify-self: center;
  margin-bottom: 0.5rem;
}

.file-status-queue li .title {
  /* grid-area: title; */
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  font-family: circular;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  margin-top: 15px;
}

.file-status-queue li .date {
  font-family: circular;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  padding: 0px;
  margin: -10px 0px 0px 0px;
}

.lod-table {
  box-shadow: 0px 0px 4px 2px rgba(30, 30, 30, 0.23) !important;
  border-radius: 12px !important;
}

.document-number-container {
  /* width: 109%;
    margin-left: -25px; */
}

.flowchart-img {
  max-width: 100%;
  height: auto;
}

.flowchart-container {
  box-shadow: 0px 0px 4px 2px rgba(30, 30, 30, 0.23) !important;
  border-radius: 12px;
  padding: 3%;

  .title {
    font-family: Inter;
    font-size: 28.17px;
    font-weight: 600;
    text-align: left;
  }

  .flow-chart {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
  }
}

.question-container {
  box-shadow: 0px 0px 4px 2px rgba(30, 30, 30, 0.23) !important;
  border-radius: 12px;
  padding: 3%;

  .title {
    font-family: circular;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
  }

  .q-para {
    font-family: Inter;
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    margin: auto;
  }

  div {
    /* height: 350px !important; */
    overflow-y: scroll !important;
  }
}

.query-details {
  .title {
    font-family: circular;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
  }

  .query-data {
    padding: 5px;
    border-radius: 16px;
    border: 0.5px solid black;
    gap: 65px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    /* height: 38px; */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.13);
  }

  .query-data p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin: revert;
  }
}

.query-table-data {
  box-shadow: 0px 0px 4px 2px rgba(30, 30, 30, 0.23) !important;
  border-radius: 12px;
  /* padding: 3%; */
}

.new-scroll::-webkit-scrollbar {
  width: 5px !important;
  /* background-color: black; */
}

.new-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px gray;
  border-radius: 10px;
}

.new-scroll::-webkit-scrollbar-thumb {
  /* background: rgba(230, 106, 106, 0.653);  */
  background: rgb(113, 113, 113);
  border-radius: 10px;
}

.sticky-table-container {
  position: relative;
  overflow: auto;
  /* height: 480px; */
  /* Set the desired height for the table container */
}

.sticky-table-header {
  position: sticky;
  top: 0;
  background-color: #6c757d;
  /* Set the background color according to your design */
  /* z-index: 1; */
}

@media (min-width: 320px) and (max-width: 768px) {
  .query-table-data {
    margin-top: 5% !important;
    width: 210% !important;
    overflow-x: scroll;
  }

  .lod-table table {
    overflow-x: scroll !important;
    width: 250% !important;
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .query-details {
    .query-data {
      display: grid !important;
      height: auto !important;
      gap: 0px !important;
    }
  }
}
