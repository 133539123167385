.bg-purple {
    background: rgba(119, 89, 222, 1) !important;
}

.bg-primary {
    background: rgba(33, 150, 243, 1) !important;
}

.bg-warning {
    background: rgba(255, 152, 0, 1);
}

.doc-no-card {
    border: none !important;
    border-radius: 12px !important;
    box-shadow: 0px 0px 4px 2px rgba(30, 30, 30, 0.23) !important;
    color: black !important;
    /* padding: 2% !important; */
    /* height: 220px !important; */
    /* width: 350px !important; */
}

.doc-no-card .title {
    font-family: circular;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.doc-no-card .doc-no-count {
    font-family: circular;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.doc-no-card span {
    font-family: circular;
    color: white;
}

.support-bar [class*="col"] {
    border-right: 1px solid #fff;
    color: black;
}

.border-btm-radius {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

@media (min-width:320px) and (max-width:768px) {
    .document-number-container {
        gap: 20px;
    }
}