.card {
    border-radius: 16px 0px 0px 0px;
    /* opacity: 0px; */
    background: white;
    box-shadow: 0px 0px 30px 2px #0C0A0B21;
}

/* .card-zindex {
    z-index: -1;
} */

/* .card-header {
    background: white !important;
    border: none !important;
}

.card-header h5 {
    font-family: circular;
    font-size: 26px;
    font-weight: 700;
    line-height: 30.5px;
    margin-left: 25%
} */
.css-1u9des2-indicatorSeparator {
    background-color: white !important;
}

.css-13cymwt-control {
    border-radius: 14px !important;
    box-shadow: 0 0 5px 2px #0c0a0b40 !important;
    padding: 5px !important;
}

.card-content {}

.form-field {
    height: 47px;
    padding: 14px;
    border-radius: 14px;
    box-shadow: 0px 0px 5px 2px rgba(12, 10, 11, 0.25);
    border: none;
    color: black;
    display: flex;
    justify-content: space-between;
}

.form-field input {
    border: none;
    outline: none;
    width: 100%;
}

.select-field select {
    width: 100%;
    height: 47px;
    padding: 0px 0px 0px 20px;
    border-radius: 14px;
    box-shadow: 0px 0px 5px 2px rgba(12, 10, 11, 0.25);
    border: none;
    color: black;
    outline: none;
    align-items: center;
}

.select2-container--default .select2-selection--multiple {
    padding: 7px 0px 0px 12px !important;
    width: 100% !important;
    min-height: 47px !important;
    border-radius: 14px !important;
    box-shadow: 0px 0px 5px 2px rgba(12, 10, 11, 0.25) !important;
    border: none !important;
    color: black !important;
    outline: none !important;
}

/* .select2-container .select2-selection--multiple .select2-selection__rendered::after {
    content: url('../../../assets/sidebar/down-Arrow.png');
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 20px; 
    height: 20px;
  } */

.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    padding-right: 30px;
}

/* calendarIcon */

.sumbit-btn {
    width: auto !important;
    height: 47px !important;
    padding: 12.32px 49.27px 12.32px 49.27px;
    border-radius: 13px;
    box-shadow: -1.61px -2.14px 3.75px 0px #FFFFFF26 inset;
    border: none;
    background: radial-gradient(100% 100% at 0% 0%, #0F090C 0%, #0F090C 100%);
    color: white;
    margin: auto;
}

.dash-sumbit-btn {
    width: auto !important;
    height: 47px !important;
    padding: 12.32px 49.27px 12.32px 49.27px;
    border-radius: 13px;
    box-shadow: -1.61px -2.14px 3.75px 0px #FFFFFF26 inset;
    border: none;
    background: radial-gradient(100% 100% at 0% 0%, #0F090C 0%, #0F090C 100%);
    color: white;
    margin-top: 28px !important;
}

.file-card {
    width: 250px;
    height: 125px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 30px 2px #0C0A0B21;
    padding: 12px;
}

.file-card img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.file-title {
    margin: auto 10px;
    font-family: circular;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
}

.file-count {
    font-family: circular;
    font-size: 25px;
    font-weight: 600;
    line-height: 39.2px;
    text-align: left;
    margin: 0px 0px 0px 60px;
}

.view-all-text {
    float: right;
    font-family: circular;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    margin: 0px auto;
    color: rgba(102, 37, 37, 1);
}

.view-all-text-div {
    position: relative;
    bottom: -6px;
}

.react-datepicker__month {
    background-color: white;
    position: relative !important;
    z-index: 999 !important;
}

.select2-selection select2-selection--multiple {
    z-index: -1 !important;
}

.select2 select2-container select2-container--default {
    z-index: -1 !important;
}



.eod-table {
    overflow-x: scroll;
}

.mobile-view-file-table {
    margin-top: 3rem !important;
}

.mobile-view-dash-filter {
    margin-top: 3rem !important;
}

.react-datepicker {
    display: inline-table;
}

.mobile-view-search {
    gap: 30px 0px;
}

@media (min-width:320px) and (max-width:1200px) {
    .form-field {
        width: auto !important;
    }

    .select-field select {
        width: 100% !important;
    }

    .select2-container--default .select2-selection--multiple {
        width: 100% !important;
    }
}


@media (min-width:768px) and (max-width:1000px) {
    .mobile-view-files-list {
        gap: 20px;
        display: grid !important;
        grid-template-columns: auto auto !important;
        justify-content: space-between !important;
    }

    .sumbit-btn {
        width: 100px !important;
        padding: 0px 0px 0px 0px;
    }
}

@media (min-width:1001px) and (max-width:1275px) {
    .mobile-view-files-list {
        gap: 20px;
        display: grid !important;
        grid-template-columns: auto auto auto !important;
        justify-content: space-between !important;
    }
}

@media (min-width:320px) and (max-width:767px) {

    /* smartphones, iPhone, portrait 480x320 phones */
    .mobile-view-dash-filter {
        margin-top: 2rem !important;
    }

    .card-header h5 {
        margin-left: 30% !important;
    }

    .react-datepicker {
        margin-top: 34px;
        margin-left: -206px !important;
        display: inline-table;
    }

    .mobile-view-search {
        gap: 20px !important;
    }

    .mobile-view-files-list {
        gap: 20px !important;
        margin-bottom: 5% !important;

        .file-card {
            width: 100% !important;
        }
    }

    .mobile-view-file-table {
        margin: 1% !important;
        margin-bottom: 5% !important;
        margin-top: 2rem !important;

        .table-container {
            overflow-x: scroll !important;
        }

        table {
            width: 200% !important;
        }
    }

    .mobile-view-file-table-mis-report {
        margin: 1% !important;
        margin-bottom: 5% !important;

        .table-container {
            overflow-x: scroll !important;
        }

        table {
            width: 600% !important;
        }
    }

    .mobile-view-file-table-eod-report {
        margin: 1% !important;
        margin-bottom: 5% !important;

        .table-container {
            overflow-x: scroll !important;
        }

        table {
            width: 350% !important;
        }
    }

    .back-button {
        margin-bottom: 20px !important;
    }

    .search-field {
        margin-top: 20px !important;
    }

    .download-btn {
        margin-top: 20px;
    }

    .formsubmit {
        justify-content: center;
        display: flex;
        align-items: center;
        margin-top: 20px;
    }
}